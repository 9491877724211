.App {
  text-align: center;
}
/* styles.css */
.zoom-effect {
  transition: transform 0.3s ease-in-out;
}

.zoom-effect:hover {
  transform: scale(1.1); /* Adjust scale factor as needed */
}



.menu {
  position: absolute;
  left: 0;
  top: 9%;
  height: screen;
  width: 70%;
  background-color: white;
  z-index: 10000;
  transition: left 0.3s ease;
  box-shadow: 2px 0 5px rgba(0,0,0,0.3);
}

.menu.open {
  left: 0;
}


.blackButton {
  @apply cursor-pointer rounded-md bg-richblack-800 px-[20px] py-[8px] font-semibold text-richblack-5;
}
.lable-style {
  @apply text-[14px] text-richblack-5;
}
.form-style {
  @apply rounded-lg bg-offwhite p-3 text-[16px] leading-[24px] text-richblack-700 shadow-[0_1px_0_0] shadow-white/50 placeholder:text-richblack-400 focus:outline-none;
}
.section_heading {
  @apply text-2xl font-bold text-richblack-5 lg:text-4xl;
}

/* Define keyframes for the animation */
/* @keyframes animateBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 100% 50%;
  }
} */

/* Apply the animation to the background
.animated-background {
  background-size: 200% 200%;
  animation: animateBackground 15s infinite linear;
} */

/* Keyframes for rotating background */
@keyframes rotateBackground {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the background */
.animated-background {
  animation: rotateBackground 60s linear;
}

/* Fade-in effect for images */
.fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
